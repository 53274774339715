<script setup lang="ts">
import type { ComponentBannerCategoriesOfTheMomentProps } from "./ComponentBannerCategoriesOfTheMoment.props"
const props = defineProps<ComponentBannerCategoriesOfTheMomentProps>()
</script>

<template>
  <div class="cms-component-banner-categories-of-the-moment">
    <NuxtLazyHydrate>
      <BannerTopCategories v-bind="props" />
    </NuxtLazyHydrate>
  </div>
</template>
